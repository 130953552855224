import React, { FC, useMemo } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

const Footer: FC = () => {
  const { language } = useI18next();

  const privacyPolicyLink = useMemo(() => {
    if (language === 'ko') return 'https://line.me/ko/terms/policy/';
    if (language === 'ja') return 'https://line.me/ja/terms/policy/';

    return 'https://line.me/en/terms/policy/';
  }, [language]);

  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer_inner">
          <p className="copyright">©2021 LINE Corporation</p>
          <ul className="footer_link">
            <li className="item">
              <Link to="/code_of_conduct" state={{ modal: true }}>
                Code of Conduct
              </Link>
            </li>
            <li className="item">
              <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </li>
            <li className="item">
              <a href="mailto:dl_contact_devday21@linecorp.com">Contact</a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
