import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

export const getAuthState = (state: RootState) => state.auth;

export const getIsInitialized = createSelector(getAuthState, (state) => state.isInitialized);
export const getIsRegistered = createSelector(getAuthState, (state) => {
  return !!state.me;
});
export const getBookmark = createSelector(getAuthState, (state) => {
  return state.me ? state.me.bookmark_sessions : [];
});
export const getMe = createSelector(getAuthState, (state) => {
  return state.me;
});

export const getIsShowPopup = createSelector(getAuthState, (state) => state.isShowPopup);
