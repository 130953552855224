import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export function useScroll() {
  const [scrollY, setScrollY] = useState<number>(0);

  const listener = () => {
    if (typeof window !== 'undefined') {
      setScrollY(window.pageYOffset);
    }
  };

  const DEBOUNCE_TIME = 15;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = debounce(listener, DEBOUNCE_TIME);
      window.addEventListener('scroll', handler);
      return () => window.removeEventListener('scroll', handler);
    }
  });

  return {
    scrollY,
  };
}
