import { useDispatch, useSelector } from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';
import { actions } from '@/store/auth/slice';
import * as authSelector from '@/store/auth/selectors';
import { Participant } from '@/types/participant';

export const useAuthStore = () => {
  const dispath = useDispatch();

  const isRegistered = useSelector(authSelector.getIsRegistered);
  const isInitialized = useSelector(authSelector.getIsInitialized);
  const bookmark = useSelector(authSelector.getBookmark);
  const me = useSelector(authSelector.getMe);
  const isShowPopup = useSelector(authSelector.getIsShowPopup);

  const setIsInitialized = (newState: boolean) => {
    dispath(actions.setIsInitialized(newState));
  };

  const setMe = (newState: Participant) => {
    dispath(actions.setMe(newState));
  };

  const setBookmark = (newState: number[]) => {
    dispath(actions.setBookmark(newState));
  };

  const setIsShowPopup = (newState: boolean) => {
    dispath(actions.setIsShowPopup(newState));
  };

  return {
    isRegistered,
    isInitialized,
    isShowPopup,
    bookmark,
    me,
    setMe,
    setIsInitialized,
    setBookmark,
    setIsShowPopup,
  };
};

export const useBeforeunloadLogout = () => {
  useBeforeunload(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('isRedirected');
    }
  });
};
