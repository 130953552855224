import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { useI18next, Link } from 'gatsby-plugin-react-i18next';

import { useAuthStore } from '@/hooks/auth';
import mainHeaderLogoImage from '@/assets/img/logo@2x.png';
import { useScroll } from '@/hooks/scroll';

const Header: FC = () => {
  const { t, changeLanguage, languages, language } = useI18next();
  const { isInitialized } = useAuthStore();
  const { scrollY } = useScroll();
  const [isOpenGNB, setIsOpenGNB] = useState<boolean>(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

  if (!isInitialized) {
    return <></>;
  }

  return (
    <>
      <header id="header" className={classnames('header', [isOpenGNB && 'gnb_open'], [scrollY > 0 && 'scroll'])}>
        <h1 className="logo">
          <Link to="/">
            <img src={mainHeaderLogoImage} />
          </Link>
        </h1>
        <button type="button" className="btn_gnb" onClick={() => setIsOpenGNB(!isOpenGNB)}>
          <span className="blind">Open/Close</span>
          <span className="mask1" />
          <span className="mask2" />
        </button>
        <div className="gnb_wrap">
          <nav id="gnb" className="gnb">
            <ul className="dep1">
              <li onClick={() => setIsOpenGNB(false)}>
                <Link to="">{t('top.navigation.home')}</Link>
              </li>
              <li onClick={() => setIsOpenGNB(false)}>
                <Link to="/#about">{t('top.navigation.about')}</Link>
              </li>
              <li onClick={() => setIsOpenGNB(false)}>
                <Link to="/#sessions">{t('top.navigation.sessions')}</Link>
              </li>
            </ul>
          </nav>
          <div
            className={`language_list ${isOpenDropdown ? 'is_active' : ''}`}
            onClick={() => setIsOpenDropdown(!isOpenDropdown)}
          >
            <button type="button" className="btn">
              <span className="lang">{language}</span>
            </button>
            <ul className="list_layer">
              {languages.map((lang, index) => (
                <li key={index} onClick={() => changeLanguage(lang)}>
                  <a className={classnames(language === lang && 'is_active')}>{lang}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
